import axios from "axios";
import { FALLBACK_LOCALE, LOCALE } from "../../constants";
import Article from "../../models/Article";

export type FailureResponse = {
    status_code: number;
    code: string;
    errors?: {
        error?: string | string[];
    };
};

export type SuccessResponse = {
    results: Article[];
    next: string;
    previous: string;
};

export const listArticles = async (
    cursor?: string
): Promise<SuccessResponse> => {
    const token = localStorage.getItem("token");
    const url = `${
        process.env.REACT_APP_API_BASE_URL || window.location.origin.toString()
    }/blog/articles`;

    try {
        const response = await axios.get(url, {
            headers: {
                Authorization: token ? `Token ${token}` : "",
                "Accept-Language":
                    localStorage.getItem(LOCALE) || FALLBACK_LOCALE,
            },
            params: {
                cursor,
            },
        });
        response.data.results = response.data.results.map(
            (item: Article) => new Article(item)
        );
        return response.data;
    } catch (error: any) {
        throw error.response.data;
    }
};
