import { parseDate } from "../utils/helpers";

class Article {
    readonly slug: string;
    readonly thumbnail_url: string;
    readonly title: string;
    readonly author: string;
    readonly dt_published: string;
    readonly url: string;
    readonly categories: string[];
    readonly tags: string[];

    constructor(data: any) {
        this.slug = data.slug;
        this.thumbnail_url = data.thumbnail_url;
        this.title = data.title;
        this.author = data.author;
        this.dt_published = parseDate(data.dt_published);
        this.url = `${process.env.REACT_APP_API_BASE_URL}/blog/articles/${data.slug}`;
        this.categories = data.categories;
        this.tags = data.tags;
    }
}

export default Article;
