import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import classes from "./AboutPage.module.scss";

const AboutPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div
                className={`d-flex flex-column gap-3 p-3 form-text text-dark container ${classes.content}`}
            >
                <h2 className="mb-3">About</h2>
                <div>
                    <h5>What is Ablebees?</h5>
                    <p>
                        Ablebees is a petition and crowdfunding platform where
                        like-minded subscribers start a crowdfunded petition
                        requesting their favorite YouTube content creator to
                        make a video on a particular topic.
                    </p>
                </div>
                <div>
                    <h5>How does Ablebees work?</h5>
                    <ul className="d-flex flex-column gap-3 mt-4">
                        <li>
                            A subscriber of a YouTube content creator starts a
                            petition requesting them to make a video on a
                            particular topic.
                        </li>
                        <li>
                            Other subscribers support the petition if they also
                            want the same video to be made by the same creator.
                        </li>
                        <li>
                            The starter and supporters of the petition pledge
                            any amount each are willing to pay for the requested
                            content to crowdfund the its production and thus
                            incentivize the creator to accept the petition.
                        </li>
                        <li>
                            YouTube content creators can see how many people
                            have supported each petition addressed to them and
                            how much money has been raised for for each one of
                            them. This helps creators easily evaluate the actual
                            demand for any particular video topic.
                        </li>
                        <li>
                            A petition is active for up to the number of days
                            set by the starter of the petition. At any point
                            during this period the creator can accept the
                            petition if they consider the amount of support
                            attracted by the petition incentivizing enough. The
                            creator should make sure they have sufficient time
                            until the end of the set period to actually make the
                            video and mark the petition as fulfilled.
                        </li>
                        <li>
                            As soon as the creator accepts a petition no further
                            support for the petition is accepted.
                        </li>
                        <li>
                            The content creator produces the requested video and
                            uploads it on their YouTube channel.
                        </li>
                        <li>
                            The content creator shares the link to the YouTube
                            video on Ablebees marking the petition as fulfilled.
                            Once the petition is marked as fulfilled, the raised
                            funds become available to be transferred to the
                            creator's bank account.
                        </li>
                        <li>
                            Supporters of the fulfilled petition rate the end
                            result - the new video - on Ablebees.
                        </li>
                    </ul>
                </div>
                <div>
                    <h5>How do subscribers benefit from Ablebees?</h5>
                    <p>
                        If you actively follow a YouTube content creator, over
                        time you may have ideas for new videos you would like to
                        suggest the content creator. However, you might be
                        hesitant to comment your suggestion on YouTube as you
                        doubt it will be noticed among hundreds other comments.
                        Moreover, you are sceptical about your suggestion being
                        actually taken into consideration in case it does get
                        noticed by the content creator.
                    </p>
                    <p>
                        Ablebees helps you unite with like-minded people who
                        would like to see the same video produced by your
                        favorite content creator. Furthermore, on Ablebees you
                        can not only unite to start a petition, but also each
                        pledge comfortable amount of money to demonstrate the
                        demand and make the content creator seriously consider
                        your request.
                    </p>
                </div>
                <div>
                    <h5>How do content creators benefit from Ablebees?</h5>
                    <p>
                        With so many content creators on YouTube, it is hard to
                        keep attention of your audience while trying to come up
                        with new video ideas. If you take your YouTube channel
                        seriously, you should regularly release new videos. The
                        bigger your audience is the more time and resources you
                        invest in creating each next video, thus the more risk
                        of return on investment is associated with each released
                        video.
                    </p>
                    <p>
                        Ablebees helps you mitigate those risks. On Ablebees
                        you:
                        <ul className="d-flex flex-column gap-3 mt-3">
                            <li>
                                get a constant stream of in demand ideas from
                                your audience
                            </li>
                            <li>
                                fund production of new videos by money raised
                                for each petition
                            </li>
                            <li>
                                increase subscriber loyalty by demonstrating
                                your appreciation of subscribers’ ideas
                            </li>
                            <li>
                                organically grow your channel as subscribers
                                whose requests are fulfilled become active
                                promoters of your channel sharing the created
                                content with their friends.
                            </li>
                        </ul>
                    </p>
                    <p>
                        Ablebees helps you easily know what the actual demand is
                        for each suggested video topic by seeing the number of
                        people supporting each petition and the total amount of
                        money being pledged. Speaking of money, Ablebees allows
                        you to crowdfund the requested videos helping you reduce
                        risks and increase chances of the video being popular.
                    </p>
                </div>
                <div>
                    <h5>What is new about Ablebees?</h5>
                    <p>
                        Ablebees introduces a new type of relationships among
                        content creators and their subscribers. The demand is
                        already in the air as we see lots of examples of video
                        suggestions attracting thousands of likes in YouTube
                        comments. Ablebees helps subscribers come together to
                        demonstrate the scale of the hidden demand, and helps
                        creators grow their channel by addressing this demand.
                        This is a win-win situation for both sides!
                    </p>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default AboutPage;
