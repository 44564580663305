import React, { useRef, useState } from "react";
import Footer from "../../components/Footer/Footer";
import classes from "./WelcomePage.module.scss";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import Faqs from "../FaqPage/Faqs/Faqs";
import youtube_logo from "../../images/youtube-logo-white.svg";
import { AiOutlineYoutube } from "react-icons/ai";

type UsageStepCard = {
    image: string;
    title:
        | "welcomePage.steps.step1.title"
        | "welcomePage.steps.step2.title"
        | "welcomePage.steps.step3.title"
        | "welcomePage.steps.step4.title"
        | "welcomePage.steps.step5.title"
        | "welcomePage.steps.step6.title";
    text:
        | "welcomePage.steps.step1.text"
        | "welcomePage.steps.step2.text"
        | "welcomePage.steps.step3.text"
        | "welcomePage.steps.step4.text"
        | "welcomePage.steps.step5.text"
        | "welcomePage.steps.step6.text";
    bgColor?: string;
};

type AlreadyOnAblebeesCreatorCard = {
    image_url: string;
    name: string;
    youtube_url: string;
};

const usageSteps: UsageStepCard[] = [
    {
        image: "https://storage.googleapis.com/ablebees-public/frontend/welcome/step-1.png",
        title: "welcomePage.steps.step1.title",
        text: "welcomePage.steps.step1.text",
        bgColor: "bg-white",
    },
    {
        image: "https://storage.googleapis.com/ablebees-public/frontend/welcome/step-2.png",
        title: "welcomePage.steps.step2.title",
        text: "welcomePage.steps.step2.text",
        bgColor: "bg-white",
    },
    {
        image: "https://storage.googleapis.com/ablebees-public/frontend/welcome/step-3.png",
        title: "welcomePage.steps.step3.title",
        text: "welcomePage.steps.step3.text",
        bgColor: "bg-white",
    },
    {
        image: "https://storage.googleapis.com/ablebees-public/frontend/welcome/step-4.png",
        title: "welcomePage.steps.step4.title",
        text: "welcomePage.steps.step4.text",
        bgColor: "bg-white",
    },
    {
        image: "https://storage.googleapis.com/ablebees-public/frontend/welcome/step-5.png",
        title: "welcomePage.steps.step5.title",
        text: "welcomePage.steps.step5.text",
        bgColor: "bg-white",
    },
    {
        image: "https://storage.googleapis.com/ablebees-public/frontend/welcome/step-6.png",
        title: "welcomePage.steps.step6.title",
        text: "welcomePage.steps.step6.text",
        bgColor: "bg-white",
    },
];

const alreadyOnAblebeesCreators: AlreadyOnAblebeesCreatorCard[][] = [
    [
        {
            image_url:
                "https://yt3.googleusercontent.com/gFyzdEPj7QLaU1tZCw-yWJapDVlCvJANzAsNrGa3UTfZN9-iN-jofZoQ6elUyio6IhTHu3NNIg=s176-c-k-c0x00ffffff-no-rj",
            name: "Jeff Hanson",
            youtube_url:
                "https://www.youtube.com/channel/UCXKW_dKcpFh358S1rV5qBDw",
        },
        {
            image_url:
                "https://yt3.googleusercontent.com/ytc/AIdro_m8LO8AN7NNcUGHRMH95cnmngmeN99o0nb1xnFTOw=s176-c-k-c0x00ffffff-no-rj",
            name: "Professor Dave Explains",
            youtube_url:
                "https://www.youtube.com/channel/UC0cd_-e49hZpWLH3UIwoWRA",
        },
        {
            image_url:
                "https://yt3.googleusercontent.com/uefYbPq7WlXeU9LhCIoebsRKJWy4piH3-n-gZpTR1S7BOP2wXUgDbKZz7hmGyvO5xyfIe7L-Uw=s176-c-k-c0x00ffffff-no-rj",
            name: "zhuli",
            youtube_url:
                "https://www.youtube.com/channel/UCn63G5jopR5Lq_cEiNNc9bA",
        },
    ],
    [
        {
            image_url:
                "https://yt3.googleusercontent.com/ytc/AIdro_mJnJpQJcBdeGcZG7-3UzgUXZTHD3sG613d_stbfw=s176-c-k-c0x00ffffff-no-rj",
            name: "Virtually Passed",
            youtube_url:
                "https://www.youtube.com/channel/UCOmiAigTSDgZegFMTkLl7OA",
        },
        {
            image_url:
                "https://yt3.googleusercontent.com/ytc/AIdro_kZbt6zdlfkCwjp2YFmQU9oLpy47a4oUmgKoVI0PQ=s176-c-k-c0x00ffffff-no-rj",
            name: "Eric Rowland",
            youtube_url:
                "https://www.youtube.com/channel/UCHmVAKGT0AcuD24zyjG1xYQ",
        },
        {
            image_url:
                "https://yt3.googleusercontent.com/TiVhj1-0A-1Xbkxs9cmpKQvZFxu10UhsPy3R9-_7p5rRLJLNpMVInRK0nUXQxpOW34cKZGKlbfI=s176-c-k-c0x00ffffff-no-rj",
            name: "polymathematic",
            youtube_url:
                "https://www.youtube.com/channel/UCGF5erKtI4NYUVfkmoEm2sg",
        },
    ],
    [
        {
            image_url:
                "https://yt3.googleusercontent.com/8CajKMcBX2wruDzQOJldrqGEQoEvHIN2SFOpfEKuNKhNV1gPYEyIq-NW-WKshlDeqTwr-kzjyg=s176-c-k-c0x00ffffff-no-rj",
            name: "Think Econ",
            youtube_url:
                "https://www.youtube.com/channel/UCrTEvFd4rZMl7-pMyRRQnUg",
        },
        {
            image_url:
                "https://yt3.googleusercontent.com/yVY2a66ooHvoV8NPisOVb-0vGhi9IexEtQ5ZWkXBHHJpShhiECCzpFo83Yz3AjDTY1yhskIs=s176-c-k-c0x00ffffff-no-rj",
            name: "The PhysicsMaths Wizard",
            youtube_url:
                "https://www.youtube.com/channel/UCF2IdSvpCUXPLQ1Q_r5JW1A",
        },
        {
            image_url:
                "https://yt3.googleusercontent.com/ytc/AIdro_nJpGa-DPSgsxq-lUIRZQlb6wXHXHGYax1zgZFBcg=s176-c-k-c0x00ffffff-no-rj",
            name: "Andy Field",
            youtube_url:
                "https://www.youtube.com/channel/UCakigkjm3vBzEHpFzECDXQQ",
        },
    ],
    [
        {
            image_url:
                "https://yt3.googleusercontent.com/ytc/AIdro_nOa8ewfdx2bGmADtCWYYsEuP4saC2Kp9F761_o=s176-c-k-c0x00ffffff-no-rj",
            name: "Online PM Courses - Mike Clayton",
            youtube_url:
                "https://www.youtube.com/channel/UCMZfp1_wquyegVY9SoER0Nw",
        },
        {
            image_url:
                "https://yt3.googleusercontent.com/ytc/AIdro_nFkWuIyOgFfzgkAqSG7bBeA-zCbXxX_kuCpZlRcA=s176-c-k-c0x00ffffff-no-rj",
            name: "MathTheBeautiful",
            youtube_url:
                "https://www.youtube.com/channel/UCr22xikWUK2yUW4YxOKXclQ",
        },
        {
            image_url:
                "https://yt3.googleusercontent.com/ytc/AIdro_knfMXop_2p8fU3U5rEvmF5aYiNaUkjhnnSkqXiZg=s176-c-k-c0x00ffffff-no-rj",
            name: "Anil Kumar",
            youtube_url:
                "https://www.youtube.com/channel/UC4Yoey1UylRCAxzPGofPiWw",
        },
    ],
    [
        {
            image_url:
                "https://yt3.googleusercontent.com/ytc/AIdro_kJSs-zDweQp6lpR48O94JIIpwkWYn_eiKKuffkkZ_zvn0AjGmVnA21Bze7ajUu=s176-c-k-c0x00ffffff-no-rj",
            name: "Ronald Moy, Ph.D., CFA, CFP",
            youtube_url:
                "https://www.youtube.com/channel/UCBPdqUQe7rgwfUjQFCkF3BQ",
        },
        {
            image_url:
                "https://yt3.googleusercontent.com/oEE_aamU2yoNsgLD7tZk4y-cQjfRlH3Fg4s-YCmdMQOyxT738Y36zK_texS94fFTRxlQIo6f=s176-c-k-c0x00ffffff-no-rj",
            name: "Algorithmic Simplicity",
            youtube_url:
                "https://www.youtube.com/channel/UC3sH8ShnMyB5MxavG1DaklA",
        },
        {
            image_url:
                "https://yt3.googleusercontent.com/9UAFr9Mh2SpI2redUrwB5AhC8D2ZzUjVdT9oqECfKE9sL0tmvFMYIfdMDFiJn0TsTqXtnGCB97w=s176-c-k-c0x00ffffff-no-rj",
            name: "Professor Hafner",
            youtube_url:
                "https://www.youtube.com/channel/UC-z5PT5AJK6F0ywiitW5i4w",
        },
    ],
    [
        {
            image_url:
                "https://yt3.googleusercontent.com/jff9EN7d7KYNp3zcvY2TgjDX_jzcm5Afc8mX9zpIq37ixrIUtOYnG9I4RO3nWqRd-Eelo1utDQ=s176-c-k-c0x00ffffff-no-rj",
            name: "Dot Physics",
            youtube_url:
                "https://www.youtube.com/channel/UCVxIDFY01y4n_c2lK1TB-KA",
        },
        {
            image_url:
                "https://yt3.googleusercontent.com/ytc/AIdro_mTMh8xB7Htch6or4-wDhULARuGp2LiCF6cSpnoRyYfmHs=s176-c-k-c0x00ffffff-no-rj",
            name: "Nick Heumann",
            youtube_url:
                "https://www.youtube.com/channel/UCWS7N-kpAsgxdSPQZgn9qkQ",
        },
        {
            image_url:
                "https://yt3.googleusercontent.com/D00IV5j0R-_KpVEB7BP_AY5mo4tACWNWAxY8zoPEQ91ru75ceonMFipMRp4bWOQEt8WLF1C8Jg=s176-c-k-c0x00ffffff-no-rj",
            name: "The Science Classroom",
            youtube_url:
                "https://www.youtube.com/channel/UCz1aZrCXVDCLk9vUFWMrspA",
        },
    ],
    [
        {
            image_url:
                "https://yt3.googleusercontent.com/cmMtkAbCWDB7iSs74Epg-hCo7-s1OwxKNr0TV_ZN6NEyJ4rTkuO2D6LHga6YA7KnWCb_Wwhf=s176-c-k-c0x00ffffff-no-rj",
            name: "Joshua Emmanuel",
            youtube_url:
                "https://www.youtube.com/channel/UC1S4Jeodbr5EbsCOIgBWJPQ",
        },
        {
            image_url:
                "https://yt3.googleusercontent.com/ytc/AIdro_luAigI63yiYGNBgGnwT6BbGRfBPE2Pb3QXsCX7xX3kWejQ=s176-c-k-c0x00ffffff-no-rj",
            name: "Chris Pattison",
            youtube_url:
                "https://www.youtube.com/channel/UC-EjFMzCvkFXY5AJl5Zmm3g",
        },
        {
            image_url:
                "https://yt3.googleusercontent.com/ytc/AIdro_mxwmNDCw_wcGZ7mtJo7cElrF25f_allTzy7lZ5WunsbA=s176-c-k-c0x00ffffff-no-rj",
            name: "Dr Ben Yelverton",
            youtube_url:
                "https://www.youtube.com/channel/UC12xUvcXjHxEU_2YrXreGeQ",
        },
    ],
    [
        {
            image_url:
                "https://yt3.googleusercontent.com/ytc/AIdro_mbupq1-UzKhb5ZsVXkSHkBOjWjYvKHY8Cz7530DxRc9oE=s160-c-k-c0x00ffffff-no-rj",
            name: "Attic Philosophy",
            youtube_url:
                "https://www.youtube.com/channel/UCQzOCKDVAYuUYQEawsMBO-g",
        },
        {
            image_url:
                "https://yt3.googleusercontent.com/ytc/AIdro_mTdd5akBImk8D900D2_7wKCDL3r2yxQeIj8uAJsbZEpFA=s160-c-k-c0x00ffffff-no-rj",
            name: "Steve Spicklemire",
            youtube_url:
                "https://www.youtube.com/channel/UCMnX5yrIUsv4Vde8-i5oxVA",
        },
        {
            image_url:
                "https://yt3.googleusercontent.com/ytc/AIdro_nnXqVdmtQ-KPlsXDU5VrOxKE3wi4nryCPYuKbNfwOfDik=s176-c-k-c0x00ffffff-no-rj",
            name: "Marginal Revolution University",
            youtube_url:
                "https://www.youtube.com/channel/UCnkEhPBMZcEO0QGu51fDFDg",
        },
    ],
    [
        {
            image_url:
                "https://yt3.googleusercontent.com/-v7TDy6zvDmUsUgSVlg_azwVSVg3BwlZMq87qFS7eJ6GbZgyaNW7BlbZG6CphvDgqMO0Q5229cY=s160-c-k-c0x00ffffff-no-rj",
            name: "Medina Guliyeva",
            youtube_url:
                "https://www.youtube.com/channel/UC16NiS7A1xTDEONcdR6Q2IA",
        },
    ],
];

const WelcomePage = () => {
    const { t } = useTranslation();
    const [viewAsContentCreator, setViewAsContentCreator] = useState(false);

    const renderUsageStepCard = (
        item: UsageStepCard,
        index: number,
        isLast: boolean = false
    ) => (
        <React.Fragment key={index}>
            <div
                className={`${classes["creator-type-card"]} row ${
                    index === 0 ? "pt-5" : "pt-4"
                } pb-4 mx-0 ${item.bgColor}`}
            >
                <div className="col d-flex justify-content-center px-0">
                    <div className="row container px-0 py-3">
                        <div
                            className={`col-md-6 d-flex flex-column justify-content-center ${
                                index % 2 === 1 && "order-md-2"
                            }`}
                        >
                            <h2 className="fw-normal lh-1 mb-3">
                                {t(item.title)}
                            </h2>
                            <p className="lead">{t(item.text)}</p>
                        </div>
                        <div
                            className={`col-md-6 ${
                                index % 2 === 1 && "order-md-1"
                            } d-flex justify-content-center`}
                        >
                            <img
                                className="usage-step rounded bg-white"
                                src={item.image}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/*{isLast || <hr />}*/}
        </React.Fragment>
    );

    const renderedUsageSteps = usageSteps.map(
        (item: UsageStepCard, index: number) =>
            renderUsageStepCard(item, index, index === usageSteps.length - 1)
    );

    const renderAlreadyOnAblebeesCreatorCard = (
        item: AlreadyOnAblebeesCreatorCard
    ) => (
        <div
            key={item.youtube_url}
            className="col col-md-4 border rounded border-1 d-flex flex-column align-items-center gap-3 p-3"
        >
            <img
                src={item.image_url}
                alt="profile"
                width="100"
                height="100"
                className="rounded-circle border"
            />
            <h5 className="m-0 p-0 text-center">{item.name}</h5>
            <a
                href={item.youtube_url}
                className="link-danger"
                target="_blank"
                rel="noreferrer"
                title="YouTube"
            >
                <AiOutlineYoutube className="display-6" />
            </a>
        </div>
    );

    const renderAlreaduOnAblebeesCreators = alreadyOnAblebeesCreators.map(
        (cards: AlreadyOnAblebeesCreatorCard[]) => {
            return (
                <div className="d-flex flex-column justify-content-center flex-md-row gap-3">
                    {cards.map((card: AlreadyOnAblebeesCreatorCard) =>
                        renderAlreadyOnAblebeesCreatorCard(card)
                    )}
                </div>
            );
        }
    );

    const el = useRef<null | HTMLDivElement>(null);

    const handleClick = () => {
        el.current!.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <>
            <main>
                <div className="row mx-0">
                    <div className="col px-0">
                        <div className={classes.banner}>
                            <div className={classes["video-container"]}>
                                <div className={classes["color-overlay"]} />
                                <video
                                    autoPlay={true}
                                    loop={true}
                                    muted={true}
                                    className={classes.video}
                                >
                                    <source
                                        src="https://storage.googleapis.com/ablebees-public/frontend/welcome/banner-bg-video.mp4"
                                        type="video/mp4"
                                    />
                                </video>
                            </div>
                            <div
                                className={`${classes.inner} h-100 w-100 d-flex flex-column justify-content-center align-items-center gap-3 p-0`}
                            >
                                <p className="text-center">
                                    <Trans
                                        i18nKey="welcomePage.slogan"
                                        components={[
                                            <br />,
                                            <br />,
                                            <img
                                                alt="youtube-logo"
                                                src={youtube_logo}
                                            />,
                                        ]}
                                    />
                                </p>
                                <span>
                                    <button
                                        className="btn btn-danger"
                                        onClick={handleClick}
                                    >
                                        {t("welcomePage.wantToKnowHow")}
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mx-0 pt-3" ref={el}>
                    <div className="col px-0 d-flex flex-column align-items-center gap-3">
                        <div className="row mx-0 w-100">
                            <div className="col px-0">{renderedUsageSteps}</div>
                        </div>
                    </div>
                </div>
                <div className="container py-5">
                    <div className="row justify-content-center">
                        <div className="row mb-4">
                            <div className="col text-center">
                                <h2>{t("welcomePage.alreadyOnAblebees")}</h2>
                            </div>
                        </div>
                        <div className="d-flex flex-column gap-3">
                            {renderAlreaduOnAblebeesCreators}
                        </div>
                    </div>
                </div>

                {/*FAQ*/}
                <div className="row mx-0 py-5">
                    <div className="col d-flex flex-column gap-2 align-items-center px-0">
                        <div className="row mx-0">
                            <div className="col text-center px-0">
                                <h2>{t("faqPage.frequentlyAskedQuestions")}</h2>
                            </div>
                        </div>
                        <div className="row container">
                            <div className="col">
                                <Faqs
                                    centerTabs={true}
                                    viewAsContentCreator={viewAsContentCreator}
                                    setViewAsContentCreator={
                                        setViewAsContentCreator
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/*Ready?*/}
                <div className="row mx-0 mt-5 py-5 bg-light">
                    <div className="col d-flex flex-column gap-2 align-items-center px-0">
                        <h2 className="text-center">
                            {t("welcomePage.readyToStartGetPetitions.part1")}{" "}
                            {viewAsContentCreator
                                ? t(
                                      "welcomePage.readyToStartGetPetitions.part3"
                                  )
                                : t(
                                      "welcomePage.readyToStartGetPetitions.part2"
                                  )}
                            ?
                        </h2>
                        <Link to={"/signup"} className="btn btn-danger mt-2">
                            {t("welcomePage.joinAblebees")}
                        </Link>
                    </div>
                </div>
            </main>
            <Footer showBorderTop={false} />
        </>
    );
};
export default WelcomePage;
