import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../contexts/auth-context";
import { listArticles as api_listArticles } from "../../api/blog/listArticles";
import Article from "../../models/Article";
import Spinner from "../../components/UI/Spinner/Spinner";
import Footer from "../../components/Footer/Footer";
import classes from "./Blog.module.scss";

const Blog = () => {
    const { hideNavbar } = useContext(AuthContext);
    const [articles, setArticles] = useState<Article[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // hide navbar when visiting the page
        hideNavbar(true);
        getArticles();
        return () => {
            // show navbar when leaving the page
            hideNavbar(false);
        };
    }, []);

    const getArticles = async () => {
        setIsLoading(true);
        try {
            const response = await api_listArticles();
            setArticles(response.results);
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    };

    const renderedArticles = articles.map((item: Article) => (
        <div className="col" key={item.slug}>
            <a
                href={item.url}
                target="_blank"
                className="card shadow-sm text-decoration-none"
            >
                <img
                    src={item.thumbnail_url}
                    width="100%"
                    height="225"
                    alt=""
                    className="card-img-top"
                    style={{ objectFit: "cover" }}
                />
                <div className="card-body">
                    <h5 className="mt-2">{item.title}</h5>
                    <div className="d-flex justify-content-between align-items-center">
                        <small className="text-body-secondary">
                            {item.dt_published}
                        </small>
                    </div>
                </div>
            </a>
        </div>
    ));

    return (
        <div className="row d-flex flex-column align-items-center justify-content-between">
            <div className={`${classes.content}`}>
                {isLoading && <Spinner />}
                {!isLoading && (
                    <>
                        <section className="py-5 text-center container">
                            <div className="row">
                                <div className="col-lg-6 col-md-8 mx-auto">
                                    <h1 className="fw-light mb-3">
                                        Welcome to Ablebees blog!
                                    </h1>
                                    <p className="lead text-body-secondary">
                                        Here, we share our thoughts, product
                                        updates and anything else you may find
                                        interesting about what we do.
                                    </p>
                                </div>
                            </div>
                        </section>
                        <div className="py-5">
                            <div className="container">
                                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3">
                                    {renderedArticles}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default Blog;
